$coral: #ff7473;
$yellow: #ffc952;
$light-blue: #47b8e0;
$dark-blue: #34314c;
$white: #fff;
$animation: 0.6s;
$scale: 1;

@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,700);

body {
    min-height: 90vh;
    font-family: "Roboto", sans-serif;
    font-size: 0.9375rem;
    line-height: 1.625rem;
    color: $white;
    -webkit-font-smoothing: antialiased !important;
    overflow-x: hidden;
}

main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content {
    max-width: 90%;
    text-align: center;
    color: lighten($light-blue, 25%);
    font-size: 1.4rem;
    font-weight: 300;
}

h1 {
    text-transform: uppercase;
    font-weight: 700;
    color: $coral;
    font-size: 4rem;
    line-height: 1;
    margin: 0;
    padding: 0;
}

h2 {
    margin: 1rem 0 3rem;
    padding: 0;
    text-transform: lowercase;
    font-size: 1.95rem;
    font-weight: 300;
}

p {
    line-height: 2rem;
    margin: 0 0 1rem; padding: 0;
    color: lighten($light-blue, 30%);
}

@media (min-width: 768px) {
    .content {
        font-size: 1.6rem;
    }
    h1 {
        font-size: 6rem;
    }

    h2 {
        font-size: 3rem;
    }
}
